// Note that this is a nextjs-only component as it requires the Link component

import { memo } from 'react';
import Link from '../NextLink';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Styles from './Button.module.scss';

export const ButtonType = {
  BLUE: Styles.blue,
  NEW_BLUE: Styles.blue,
  DARKBLUE: Styles.darkblue,
  WHITE: Styles.white,
  WHITE_BLUE_OUTLINE: Styles.whiteBlueOutline,
  BLUE_OUTLINE: Styles.blueOutline,
  NEW_BLUE_OUTLINE: Styles.newBlueOutline,
  DARKBLUE_OUTLINE: Styles.darkBlueOutline,
  ENTERPRISE: Styles.enterprise,
  WHITE_OUTLINE: Styles.whiteOutline,
};

export const ButtonSize = {
  FULLWIDTH: Styles.fullwidth,
  FULLWIDTH_LARGE: Styles.fullwidthLarge,
  LARGE: Styles.large,
};

const ButtonLink = memo(
  ({ type, size, to, href, className, title, children, content, as, external, ...restProps }) => {
    const parentClass = classNames(Styles.btn, type, size, className);
    const linkTitle = title ? title : '';
    return external ? (
      <a
        className={parentClass}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        title={linkTitle}
        {...restProps}>
        {content || children}
      </a>
    ) : (
      <Link as={as} href={href} className={parentClass} title={linkTitle} {...restProps}>
        {content || children}
      </Link>
    );
  }
);
ButtonLink.Type = ButtonType;
ButtonLink.Size = ButtonSize;
ButtonLink.Styles = Styles;

ButtonLink.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
};

export default ButtonLink;
