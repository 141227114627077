import Link from '@components/Link';
import classNames from 'classnames';
import Styles from '../Header.module.scss';

export const DefaultLinks = ({ children }) => {
  return (
    <div className={Styles.navList}>
      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/clients'>
          {`Clients`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/enterprise'>
          {`Enterprise`}
        </Link>
      </div>

      {/*<div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/why'>
          {`Why On Demand`}
        </Link>
      </div>*/}

      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/blog'>
          {`Blog`}
        </Link>
      </div>

      <div className={classNames(Styles.navItem, Styles.leftBorder)} id='login-action'>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/login'>
          {`Log In`}
        </Link>
      </div>
      {/*CTA COES HERE*/}
      <div className={Styles.navItem}>{children}</div>
    </div>
  );
};
