import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from './Section.module.scss';

export const ContainerType = {
  LIGHTBLUE: Styles.containerLightblue,
  DARKBLUE: Styles.containerDarkblue,
  ENTERPRISE_SPLIT: Styles.containerEnterpriseSplit,
  ENTERPRISE_FULL: Styles.containerEnterpriseFull,
  PROFESSIONAL_SPLIT: Styles.containerProfessionalSplit,
  WHITE: Styles.containerPlainWhite,
};

const SectionContainer = ({ children, className, style, anchor, type, split, ...props }) => {
  return (
    <div
      className={classNames(Styles.container, split && Styles.splitContainer, type, className)}
      style={style}
      {...props}>
      <span className={Styles.anchor} id={anchor} />

      {children}
    </div>
  );
};

SectionContainer.Type = ContainerType;
SectionContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  anchor: PropTypes.string,
  type: PropTypes.string,
  split: PropTypes.bool,
};

export default SectionContainer;
