import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Styles from './Hero.module.scss';

export const Parent = {
  MAIN: Styles.main,
  NEW_ENTERPRISE: Styles.newEnterprise,
  ENTERPRISE_COMPANY: Styles.enterpriseCompany,
  VERTICAL: Styles.vertical,
  TALENT: Styles.talent,
  CONTACT: Styles.contact,
  LOGIN: Styles.login,
  REGISTER: Styles.register,
  DOWNLOADS: Styles.downloads,
  NEW_HERO: Styles.newHero,
};

export const heroStyles = Styles;

const Hero = ({ square, angle, style, parent, containerClass, children, ...props }) => {
  return (
    <div className={classNames(parent, containerClass)} style={style}>
      <>
        {square && (
          <div className={Styles.waveContainer}>
            <div className={Styles.itemContainer}>
              <div className={Styles.angleLarge}></div>
            </div>
            <div className={Styles.itemContainer}>
              <div className={Styles.angleLarge1}></div>
            </div>
            <div className={Styles.itemContainer}>
              <div className={Styles.angleRightLarge}></div>
            </div>
            <div className={Styles.itemContainer}>
              <div className={Styles.angleRightLarge1}></div>
            </div>
          </div>
        )}
      </>
      <>
        {angle && (
          <div className={Styles.waveContainer}>
            <div className={Styles.itemContainer}>
              <div className={Styles.rightAngleLarge}></div>
            </div>
            <div className={Styles.itemContainer}>
              <div className={Styles.rightAngleLarge1}></div>
            </div>
            <div className={Styles.itemContainer}>
              <div className={Styles.rightAngleLarge2}></div>
            </div>
            <div className={Styles.itemContainer}>
              <div className={Styles.rightAngleLarge3}></div>
            </div>
          </div>
        )}
      </>
      {children}
    </div>
  );
};
Hero.Page = Parent;
Hero.Styles = Styles;

Hero.propTypes = {
  parent: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  containerClass: PropTypes.string,
  angle: PropTypes.bool,
};

export default Hero;
